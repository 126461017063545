import Modal from 'react-modal'
import theme from './theme'
import { ThemeProvider, Radio, IconButton } from '@mui/material'
import { ArrowBackIosNew, ArrowForwardIos, ChevronRight } from '@mui/icons-material'
import { useAuthorizedFetch } from '../../../../hooks'
import { uri } from '../../../../config/Api'
import { useEffect, useState } from 'react'
import SearchError from '../../../../commons/SearchError'
import LoadingIndicator from '../../../../commons/LoadingIndicator'

const DeleteLobbyReasonScreen = ({ handleSubmit, setSelectedReason, onBack }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/global/cancel-reasons`)
  const [reasons, setReasons] = useState([])

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setReasons(data)
      })
    }
  }, [loading])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0
    },
    overlay: {
      zIndex: 120,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }

  return (
    <Modal isOpen={true} style={customStyles}>
      <ThemeProvider theme={theme}>
        <div className='flex mt-2'>
          <IconButton onClick={onBack} sx={{ color: '#f25c45' }}>
            <ArrowBackIosNew />
          </IconButton>
        </div>
        {loading ? (
          <div className='flex justify-center items-center h-full'>
            <LoadingIndicator />
          </div>
        ) : !response.ok ? (
          <div className='flex justify-center items-center h-full'>
            <SearchError />
          </div>
        ) : (
          reasons && (
            <div className='flex flex-col px-6 mt-3 overflow-auto grow'>
              <div className='text-primary text-lg sm:text-center'>
                Antes de cancelar, nos gustaría saber por que
              </div>
              <div
                className='flex sm:justify-center w-full'
                style={{ height: 'calc(100vh - 150px)' }}
              >
                <div className='tall:flex tall:flex-col mt-6 w-full sm:w-[600px]'>
                  {reasons.map((x, index) => (
                    <>
                      <div
                        onClick={() => {
                          setSelectedReason(x.value)
                          handleSubmit()
                        }}
                        className='flex flex-row justify-between tall:mb-0 items-center py-5 w-full cursor-pointer'
                      >
                        <p className='shrink text-base'>{x.label}</p>
                        <ChevronRight sx={{ color: '#9ca3af', fontSize: 20 }} />
                      </div>
                      {index !== reasons.length - 1 && <div className='h-[0.5px] bg-gray w-full' />}
                    </>
                  ))}
                </div>
              </div>
            </div>
          )
        )}
      </ThemeProvider>
    </Modal>
  )
}

export default DeleteLobbyReasonScreen

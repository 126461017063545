import { tz } from 'moment-timezone'

export default expiration => {
  const expirationDate = tz(expiration, 'America/Montevideo')
  let hours = expirationDate.hours()
  let minutes = Math.ceil(expirationDate.minutes() / 10) * 10
  if (minutes === 60) {
    minutes = 0
    hours = (hours + 1) % 24
  }
  const time = `${hours < 10 ? '0' + hours : hours}:${minutes === 0 ? '00' : minutes}${
    hours >= 12 ? 'hs' : ' AM'
  }`
  const day = expirationDate.date()
  const now = tz(Date.now(), 'America/Montevideo')
  const dayText = day === now.date() ? '' : 'de mañana'
  return time + ' ' + dayText
}

import { useLocalizationContext } from '../../contexts/LocalizationContext'

const SearchError = ({ text, linkcolor, noLink }) => {
  const { config } = useLocalizationContext()

  return (
    <div className='m-auto flex flex-col justify-center items-center px-6 gap-5'>
      <img src={require('../../assets/img/error.png')} className='w-72' />
      <p className='text-lg'>¡Ups!</p>
      <p className='text-base'>
        {text || 'Ha ocurrido un error, por favor revisá tu conexión e intentá nuevamente.'}
      </p>
      {!noLink && (
        <>
          <p className='text-base'>Si el error persiste contactate con nosotros.</p>
          <div
            className={`flex-col flex gap-2 text-base ${
              linkcolor ? `text-[${linkcolor}]` : 'text-light_coral'
            }`}
          >
            <a
              href={`https://api.whatsapp.com/send/?phone=${config.phone_number}&text=Hola,%20tuve%20un%20problema%20usando%20la%20app`}
              target='_blank'
              className={linkcolor ? `text-[${linkcolor}]` : 'text-light_coral'}
            >
              WhatsApp
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default SearchError

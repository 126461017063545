import { useState, useEffect } from 'react'
import { Mixpanel } from '../../Mixpanel'
import { addDays, getLobbiesByDate } from '../../utils'
import { uri } from '../../config/Api'
import { useFetch } from '../../hooks'
import { Dots } from 'react-activity'
import 'react-activity/dist/Dots.css'

const TimeTravelButton = ({ type, fetchData, setTimeTravelLobbies }) => {
  const [loading, setLoading] = useState(false)
  const [lobbies, setLobbies] = useState({})
  const [empty, setEmpty] = useState(false)
  const updatedFetchData = {
    ...fetchData,
    origin: fetchData.origin,
    destination: fetchData.destination,
    minTime: type == 'PastLobbiesList' ? addDays(fetchData.min_time, -5) : fetchData.max_time,
    maxTime: type == 'PastLobbiesList' ? fetchData.min_time : addDays(fetchData.max_time, 5)
  }

  const handlePress = () => {
    Mixpanel.track(`Click Ver viajes ${type == 'PastLobbiesList' ? 'anteriores' : 'futuros'}`)
    setLoading(true)
  }

  const handleSuccess = lobbies => {
    const { lobbiesByDate } = getLobbiesByDate(lobbies, [])
    setLobbies(lobbiesByDate)
    if (Object.keys(lobbiesByDate).length == 0) {
      setEmpty(true)
    } else {
      setTimeTravelLobbies(lobbiesByDate)
    }
  }

  return (
    <>
      {empty ? (
        <p className='text-[#9ca3af] text-lg my-5'>
          No hay viajes {type == 'PastLobbiesList' ? 'anteriores' : 'más adelante'}
        </p>
      ) : loading ? (
        <FetchLobbies
          handleSuccess={handleSuccess}
          setLoading={setLoading}
          fetchData={updatedFetchData}
        />
      ) : (
        <p onClick={handlePress} className='text-light_coral text-lg my-5 cursor-pointer'>
          {type == 'PastLobbiesList' ? 'Ver viajes anteriores' : 'Ver viajes más adelante'}
        </p>
      )}
    </>
  )
}

const FetchLobbies = ({ handleSuccess, setLoading, fetchData }) => {
  const { loading, responseOk, data } = useFetch(
    `${uri}/lobbies?origin=${fetchData.origin}&destination=${fetchData.destination}&min_time=${fetchData.minTime}&max_time=${fetchData.maxTime}`
  )

  useEffect(() => {
    if (!loading) {
      if (responseOk) {
        handleSuccess(data)
      } else {
        Mixpanel.track('Request error', { Error: data })
      }
      setTimeout(() => setLoading(false), 1000)
    }
  }, [loading])

  return (
    <div className='flex my-5 justify-center'>
      <Dots color='#f25c45' />
    </div>
  )
}

export default TimeTravelButton

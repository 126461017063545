import { useEffect, useState } from 'react'
import { useAuth, useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import { toDate } from '../../utils'
import { MESES } from '../../config/App'
import { KeyboardReturnRounded, MonetizationOnOutlined } from '@mui/icons-material'
import LoadingIndicator from '../../commons/LoadingIndicator'
import SearchError from '../../commons/SearchError'

export default ({ transactionId }) => {
  const { isAuthenticated, loading: authLoading, loginWithRedirect } = useAuth()
  const { loading, response } = useAuthorizedFetch(`${uri}/payments/receipt/${transactionId}`)

  const [transaction, setTransaction] = useState()

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setTransaction(data)
      })
    }
  }, [loading])

  useEffect(() => {
    if (!authLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: window.location.origin + window.location.search
      })
    }
  }, [authLoading])

  if (authLoading || loading || (response.ok && !transaction)) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <LoadingIndicator />
      </div>
    )
  }

  if (!loading && !response.ok) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <SearchError />
      </div>
    )
  }

  return (
    <div className='flex flex-col items-center h-screen px-6 pt-6 pb-8 overflow-y-auto'>
      <img src={require('../../assets/img/orange-logo.png')} className='w-[100px] sm:w-[150px]' />
      <div className='flex flex-col p-6 px-4 gap-6 rounded-xl shadow-[0px_0px_5px_0px_rgba(0,0,0,0.2)] my-4'>
        <p className='text-lg font-bold text-center'>
          Comprobante de {transaction.status === 'refunded' ? 'reembolso' : 'pago'}
        </p>
        {transaction.status === 'refunded' && (
          <div className='flex flex-col gap-2'>
            <p className='text-sm font-bold'>Reembolso en proceso:</p>
            <p className='text-sm font-thin'>
              Solicitamos el reembolso a tu banco. Normalmente, los plazos de reembolso de las
              instituciones financieras son entre{' '}
              <span className='font-bold'>7 y 20 días hábiles</span>.
            </p>
          </div>
        )}
        <div className='flex flex-col gap-2'>
          <p className='text-sm font-bold'>Medio de pago:</p>
          <p className='text-sm font-thin'>
            {transaction.payment_method} ****{transaction.last_four_digits}
          </p>
        </div>
        <div className='flex flex-col gap-2'>
          <p className='text-sm font-bold'>Número de la transacción:</p>
          <p className='text-sm font-thin'>{transaction.transaction_id}</p>
        </div>
        <div className='flex flex-col gap-3'>
          <p className='text-sm font-bold'>
            Detalles del {transaction.status === 'refunded' ? 'reembolso' : 'pago'}
          </p>
          <div className='flex flex-col p-6 bg-light_gray rounded-lg gap-5'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row gap-2 items-center'>
                <MonetizationOnOutlined sx={{ fontSize: '20px' }} />
                <p className='text-sm font-bold'>Cobro:</p>
              </div>
              <p className='text-sm font-bold'>$ {transaction.price}</p>
            </div>
            <div className='bg-gray w-full h-[0.5px]' />
            <div className='flex flex-col gap-2'>
              <p className='text-sm font-bold'>Fecha de la transacción:</p>
              <p className='text-sm font-thin'>{formatDate(transaction.created)}</p>
            </div>
            {transaction.status === 'refunded' && (
              <>
                <div className='bg-gray w-full h-[0.5px]' />
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row gap-2 items-center'>
                    <KeyboardReturnRounded sx={{ fontSize: '20px' }} />
                    <p className='text-sm font-bold'>Reembolso:</p>
                  </div>
                  <p className='text-sm font-bold'>$ {transaction.refunded}</p>
                </div>
                <div className='bg-gray w-full h-[0.5px]' />
                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-bold'>Fecha de reembolso:</p>
                  <p className='text-sm font-thin'>{formatDate(transaction.refund_date)}</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <p className='text-sm font-bold'>Cliente:</p>
          <p className='text-sm font-thin'>
            {transaction.client_name} - {transaction.client_email}
          </p>
        </div>
        {transaction.status === 'refunded' && (
          <p className='text-sm text-gray font-thin'>
            Por más dudas sobre los plazos, contactá directamente al banco emisor de tu tarjeta.
          </p>
        )}
      </div>
      <div className='flex flex-row w-full justify-center items-center gap-2 mt-2'>
        <img src={require('../../assets/img/mp.png')} className='w-6' />
        <p className='text-xs text-gray'>Procesado por MercadoPago</p>
      </div>
    </div>
  )
}

const formatDate = date => {
  const day = toDate(date).getDate()
  const month = MESES[parseInt(date.substring(5, 7)) - 1].toLowerCase()
  const time = date.substring(11, 16)
  const year = date.substring(0, 4)

  if (year == new Date().getFullYear()) {
    return day + ' de ' + month + ' - ' + time + 'hs'
  }

  return day + ' de ' + month + ' ' + year + ' - ' + time + 'hs'
}

module.exports = {
  cancel: {
    free: 'Ya hay personas unidas al viaje',
    paid_for: 'Ya hay personas que pagaron por asientos en tu viaje.'
  },
  leave: {
    free: 'Tras confirmar, te sacaremos del chat y ya no tendrás asientos reservados.',
    paid_for:
      'Tras confirmar, realizaremos la devolución completa de tu dinero. Los plazos están a cargo del banco emisor de tu tarjeta.'
  },
  cancel_request: {
    free: 'Tras confirmar, se deshabilitará el chat con el conductor.',
    paid_for:
      'Tras confirmar, realizaremos la devolución completa de tu dinero. Los plazos están a cargo del banco emisor de tu tarjeta.'
  }
}

import { useEffect, useState } from 'react'
import Spinner from '../../commons/Spinner'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { getCurrency } from '../../utils'
import NextButton from './NextButton'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'

const Price = ({
  setPrice,
  price,
  setCurrency,
  currency,
  handleSubmit,
  dataToFetch,
  setHasToPay
}) => {
  const { loading, response } = useAuthorizedFetch(
    `${uri}/lobbies/price?${new URLSearchParams(dataToFetch)}`
  )
  const [fixedPrice, setFixedPrice] = useState(false)
  const [maxPrice, setMaxPrice] = useState(0)
  const lowPrice = fixedPrice - fixedPrice / 5
  const highPrice = fixedPrice + 50

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        if (!price) {
          setPrice(data.price)
        }
        setCurrency(data.currency)
        setFixedPrice(data.price)
        setMaxPrice(data.max_price)
        setHasToPay(data.has_to_pay)
      })
    }
  }, [response])

  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div>
        <div className='text-start mb-12 text-2xl'>Elija el precio por acompañante</div>
        {loading || !fixedPrice ? (
          <div className='flex flex-col justify-center items-center'>
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <Spinner
              setNumber={setPrice}
              step={currency === 'ARS' ? 250 : currency === 'USD' ? 1 : 5}
              number={price}
              text={getCurrency(currency)}
              max={maxPrice}
              min={fixedPrice <= 100 ? 0 : fixedPrice - fixedPrice / 2}
            />
            <div className='flex w-full '>
              <div
                className={`mt-6 mb-3 py-1 px-2 rounded-[8px] inline-block max-w-fit' ${
                  price < lowPrice
                    ? 'bg-[#ff6700]'
                    : price > highPrice
                    ? 'bg-[#ff0f0f]'
                    : 'bg-[#05B456]'
                }`}
              >
                <p className='w-fit text-sm text-white'>
                  Precio recomendado: {getCurrency(currency)} {fixedPrice}
                </p>
              </div>
            </div>
            <p className='text-[#9ca3af] text-sm text-start'>
              {price < lowPrice
                ? 'Podés subir un poco el precio para dividir mejor los gastos del viaje.'
                : price > highPrice
                ? 'Tu viaje es muy caro, te costará conseguir acompañantes.'
                : 'Estas en el rango de precio perfecto para este trayecto.'}
            </p>
          </>
        )}
      </div>
      <NextButton onSubmit={handleSubmit} disabled={loading} />
    </div>
  )
}

export default Price

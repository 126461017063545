import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ArrowBackIos, ChevronRight, InfoOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { formatExpiration, getCurrency } from '../../../../utils'
import Avatar from '../../../../commons/Avatar'
import Trip from '../../../Trip'
import { DIAS, MESES } from '../../../../config/App'
import Button from '../../../../commons/Button'
import LoadingIndicator from '../../../../commons/LoadingIndicator'
import { useAuthorizedFetch } from '../../../../hooks'
import { uri } from '../../../../config/Api'
import Lottie from 'lottie-react'

const PendingCompanions = ({ visible, setVisible, companions, lobby, setCompanions }) => {
  const [selectedCompanion, setSelectedCompanion] = useState(null)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 0
    },
    overlay: {
      zIndex: 500,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  }

  const handleClose = () => {
    if (selectedCompanion) {
      setSelectedCompanion(null)
    } else {
      setVisible(false)
    }
  }

  const handleCompanionClick = companion => {
    setSelectedCompanion(companion)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel='Pending Companions Modal'
    >
      <div className='w-full justify-between flex pt-3'>
        <IconButton
          onClick={handleClose}
          style={{ paddingTop: 10, paddingLeft: 22 }}
          sx={{ color: '#f25c45' }}
        >
          <ArrowBackIos />
        </IconButton>
      </div>
      {selectedCompanion ? (
        <PendingCompanion companion={selectedCompanion} lobby={lobby} />
      ) : (
        <div className='overflow-auto pt-2 px-6 sm:w-1/2 sm:mx-auto'>
          <h2 className='text-2xl mb-4 mt-4 text-primary'>Solicitudes pendientes</h2>
          {companions.map((companion, index) => (
            <div
              key={index}
              onClick={() => handleCompanionClick(companion)}
              className='mb-4 p-4 cursor-pointer flex justify-between items-center'
            >
              <p className='text-primary text-lg'>{companion.first_name}</p>
              <div className='flex gap-2 items-center'>
                <Avatar
                  src={companion.picture}
                  sx={{ width: 50, height: 50 }}
                  verified={companion.verified}
                  badgeSize={20}
                />
                <ChevronRight sx={{ color: '#d65944', fontSize: 26 }} />
              </div>
            </div>
          ))}
        </div>
      )}
    </Modal>
  )
}

const PendingCompanion = ({ companion, lobby }) => {
  const [approve, setApprove] = useState(false)
  const [reject, setReject] = useState(false)

  const departureDateTime = new Date(companion.departure_time)
  const departureTime = companion.departure_time.substr(
    companion.departure_time.indexOf('T') + 1,
    5
  )

  const departureDatetimeToShow = new Date(
    new Date(companion.departure_time).getTime() + 3 * 60 * 60 * 1000
  )

  const arrivalTime = companion.estimated_time.substr(companion.estimated_time.indexOf('T') + 1, 5)
  const departureDate =
    DIAS[departureDatetimeToShow.getDay()] +
    ' ' +
    departureDatetimeToShow.getDate() +
    ' de ' +
    MESES[departureDatetimeToShow.getMonth()]

  return (
    <div className='flex flex-col h-[94vh] pb-6 overflow-auto pt-2 px-6 sm:w-1/2 sm:mx-auto justify-between'>
      <div>
        <Trip
          start={companion.start_name}
          end={companion.end_name}
          originName={lobby.Journey.origin_name.formatted_address}
          destinationName={lobby.Journey.destination_name.formatted_address}
          departureDate={departureDate}
          departureTime={departureTime}
          arrivalTime={arrivalTime}
          dateStyle={{ fontWeight: '500', fontSize: 22 }}
        />
        <div className='w-full h-[0.5px] bg-gray mb-6' />
        <div className='mb-4 p-4 flex justify-between items-center'>
          <p className='text-primary text-lg'>{companion.first_name}</p>
          <div className='flex gap-2 items-center'>
            <Avatar
              src={companion.picture}
              sx={{ width: 50, height: 50 }}
              verified={companion.verified}
              badgeSize={20}
            />
            <ChevronRight sx={{ color: '#d65944', fontSize: 26 }} />
          </div>
        </div>
        <div className='flex w-full justify-between items-center px-4 text-lg font-thin'>
          <p>{companion.seats > 1 ? `${companion.seats} asientos` : '1 asiento'}</p>
          <div className='flex gap-2'>
            <p className='text-primary'>{getCurrency(lobby.currency)}</p>
            <div className='flex flex-row font-thin'>{companion.price}</div>
          </div>
        </div>
        <div className='w-full h-[0.5px] bg-gray my-8' />
        <div className='flex items-center gap-4'>
          <InfoOutlined sx={{ color: '#9ca3af', fontSize: 24 }} />
          <p className='text-gray text-base'>
            La solicitud expira a las {formatExpiration(companion.expiration)}
          </p>
        </div>
      </div>
      <div className='w-full flex justify-center gap-4'>
        {approve ? (
          <ApproveRequest
            lobbyId={lobby._id}
            companionId={companion.user_id}
            setApprove={setApprove}
          />
        ) : reject ? (
          <RejectRequest
            lobbyId={lobby._id}
            companionId={companion.user_id}
            setReject={setReject}
          />
        ) : (
          <>
            <Button
              onClick={() => setReject(true)}
              name='Rechazar'
              style={{ backgroundColor: 'white', color: '#d65944', border: '1px solid #9ca3af' }}
            />
            <Button name='Aceptar' onClick={() => setApprove(true)} />
          </>
        )}
      </div>
    </div>
  )
}

const ApproveRequest = ({ lobbyId, companionId, setApprove }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/approve-companion/${lobbyId}`, {
    method: 'POST',
    body: JSON.stringify({
      companion_id: companionId
    })
  })

  useEffect(() => {
    if (response.ok) {
      setTimeout(() => {
        window.location.href = window.location.origin + '/trips/' + lobbyId
      }, 2000)
    } else if (!loading) {
      alert('Error', 'No se pudo aceptar la solicitud')
      setApprove(false)
    }
  }, [response])

  if (loading) return <LoadingIndicator />

  if (response.ok)
    return (
      <Lottie
        animationData={require('../../../../assets/animations/success.json')}
        autoPlay
        loop={false}
        style={{ width: 60, height: 60 }}
      />
    )
}

const RejectRequest = ({ lobbyId, companionId, setReject }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/reject-companion/${lobbyId}`, {
    method: 'POST',
    body: JSON.stringify({ companion_id: companionId })
  })

  useEffect(() => {
    if (response.ok) {
      setTimeout(() => {
        window.location.href = window.location.origin + '/trips/' + lobbyId
      }, 2000)
    } else if (!loading) {
      alert('Error', 'No se pudo rechazar la solicitud')
      setReject(false)
    }
  }, [loading])

  if (loading) return <LoadingIndicator />

  if (response.ok)
    return (
      <Lottie
        animationData={require('../../../../assets/animations/success.json')}
        autoPlay
        loop={false}
        style={{ width: 60, height: 60 }}
      />
    )
}

export default PendingCompanions

import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from '@mui/material'
import { Mixpanel } from '../../Mixpanel'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import { useEffect } from 'react'

export default ({ cards, setSavedCards, onPress, data }) => {
  const { loading, response } = useAuthorizedFetch(
    `${uri}/payments/saved-cards?currency=${data.currency}`
  )

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setSavedCards(data.cards)
      })
    }
  }, [loading])

  return (
    <>
      <p className='mb-3 text-start text-lg'>Tus tarjetas guardadas</p>
      <div className='rounded-lg flex flex-col items-center w-full bg-white shadow-sm mb-6'>
        {loading || cards?.length === 0 ? (
          <DefaultCard loading={loading} />
        ) : (
          cards?.map((card, index) => (
            <div key={index} className='flex flex-col w-full'>
              <SavedCard card={card} onClick={() => onPress(card)} />
              {index !== cards.length - 1 && <div className='w-full h-[3px] bg-light_gray' />}
            </div>
          ))
        )}
      </div>
    </>
  )
}

const SavedCard = ({ card, onClick }) => {
  const { issuer, payment_method, last_four_digits } = card

  const cardType = payment_method.payment_type_id === 'credit_card' ? 'crédito' : 'débito'

  const image = () => {
    try {
      return require(`../../assets/img/${issuer.name.toLowerCase()}.webp`)
    } catch (error) {
      return require('../../assets/img/default_card.webp')
    }
  }

  return (
    <div
      className='rounded-lg flex flex-row items-center w-full cursor-pointer py-5 px-5'
      onClick={onClick}
    >
      <div className='flex items-center justify-center rounded-full p-2 border-2 border-light_gray'>
        <img src={image()} className='w-6 h-6' />
      </div>
      <div className='ml-4 text-start font-thin'>
        <p className='flex text-base items-start gap-1'>
          {issuer.name} <span className='text-xs align'>****</span> {last_four_digits}
        </p>
        <p className='text-gray text-sm'>
          {issuer.name} {cardType}
        </p>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className='w-3 h-3 text-gray ml-auto' />
    </div>
  )
}

const DefaultCard = ({ loading }) => {
  return (
    <div className='rounded-lg flex flex-row items-center w-full py-5 px-5'>
      {loading ? (
        <>
          <Skeleton variant='circular' width={40} height={40} />
          <div className='ml-4 text-start font-thin'>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='text' width={70} />
          </div>
        </>
      ) : (
        <p className='text-gray text-sm'>Aún no tenés tarjetas guardadas</p>
      )}
    </div>
  )
}

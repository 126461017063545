import { useEffect, useRef, useState } from 'react'
import { useAuth, useAuthorizedFetch } from '../../hooks'
import { Mixpanel } from '../../Mixpanel'
import { uri } from '../../config/Api'
import LoadingIndicator from '../../commons/LoadingIndicator'
import ChatBubble from './ChatBubble'
import Input from './Input'
import { containsContactInfo, formatChatDate, sortChannels } from '../../utils'
import { CHAT_ADMIN_PROFILE } from '../../config/App'
import { LocalPhoneRounded, WhatsApp } from '@mui/icons-material'
import { namespace } from '../../config/Auth0'

export default ({
  selectedChannelId,
  selectedChannel,
  socket,
  channels,
  setChannels,
  user,
  socketDisconnected,
  refetchChannels,
  setErrorMessage,
  setUserInfo,
  embedded
}) => {
  const { getAccessTokenSilently } = useAuth()
  const { loading, response } = useAuthorizedFetch(`${uri}/users/me/channels/${selectedChannelId}`)
  const [channel, setChannel] = useState()
  const [messages, setMessages] = useState()
  const [users, setUsers] = useState()
  const [readChannel, setReadChannel] = useState(false)
  const bottomRef = useRef()

  const readMessages = async () => {
    const token = await getAccessTokenSilently()
    const request = await fetch(`${uri}/users/me/channels/${selectedChannelId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${token}`
      }
    })

    if (request.ok === false) return

    const read = await request.json().then(data => data.read)

    setReadChannel(true)

    window.ReactNativeWebView?.postMessage(`read-messages-${read}`)
  }

  useEffect(() => {
    if (readChannel && messages && channel) {
      setMessages(
        messages.map(message => {
          return {
            ...message,
            read_by: [...message.read_by, user]
          }
        })
      )

      setChannels(
        sortChannels([
          ...channels.filter(channel => channel._id !== selectedChannelId),
          {
            ...channel,
            messages: messages.map(message => {
              return {
                ...message,
                read_by: [...message.read_by, user]
              }
            })
          }
        ])
      )
      setReadChannel(false)
    }
  }, [readChannel, messages, channel])

  useEffect(() => {
    if (response.ok) {
      response.json().then(async data => {
        setChannel(data)
        setMessages(data.messages)
        setUsers(data.users)
        const unreadMessages = data.messages
          .filter(message => message.user_id != user)
          .some(message => !message.read_by?.includes(user))
        if (unreadMessages) {
          await readMessages(data)
        }
      })
    } else if (!loading) {
      Mixpanel.track('Error al obtener los mensajes', {
        Chat: selectedChannelId,
        Error: response
      })
      setChannel(selectedChannel)
      setMessages(selectedChannel.messages)
      setUsers(selectedChannel.users)
    }
  }, [loading])

  useEffect(() => {
    const updateMessages = async () => {
      const channelMessages = channels.find(channel => channel._id === selectedChannelId)?.messages
      if (channelMessages?.length < messages?.length) {
        setMessages(
          messages.map(message => {
            return {
              ...message,
              not_sent: false
            }
          })
        )
      } else {
        setMessages(channelMessages)
      }
      if (channelMessages?.length > messages?.length) {
        await readMessages(channels.find(channel => channel._id === selectedChannelId))
      }
    }

    updateMessages()
  }, [channels])

  const handleSendMessage = message => {
    if (containsContactInfo(message) && channel.type === 'direct-message') {
      setErrorMessage('El mensaje no puede contener información de contacto')
      return
    }
    socket.current.emit('send', {
      channel_id: channel._id,
      text: message
    })
    let newMessage = {
      id: messages.length + 1,
      text: message,
      user_id: user,
      date: new Date().toISOString(),
      read_by: [],
      user: {}
    }
    if (socketDisconnected) {
      newMessage = {
        ...newMessage,
        not_sent: true
      }
    }
    setMessages([...messages, newMessage])
    setChannels(
      sortChannels([
        ...channels.filter(channel => channel._id !== selectedChannelId),
        {
          ...channel,
          messages: [...messages, newMessage]
        }
      ])
    )
  }

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }, [messages])

  return (
    <div className='flex flex-col justify-end w-full h-full rounded-tl-xl pb-4'>
      {channel?.driver_phone_number && (
        <DriverPhoneActions
          lobbyId={channel?.lobby_id}
          phoneNumber={channel?.driver_phone_number}
        />
      )}
      <div className='flex flex-col overflow-auto gap-2 bg-light_gray p-3 h-screen'>
        {messages && users ? (
          <>
            <MessageList
              embedded={embedded}
              channelId={selectedChannelId}
              messages={messages}
              user={user}
              users={users}
              setUserInfo={setUserInfo}
            />
            {(socketDisconnected || refetchChannels) &&
              channel?.type !== 'broadcast' &&
              !channel?.disabled && (
                <div className='w-full flex items-center justify-center my-2'>
                  <LoadingIndicator type={'bounce'} />
                </div>
              )}
            <div ref={bottomRef} />
          </>
        ) : (
          <div className='h-full w-full flex items-center justify-center'>
            <LoadingIndicator />
          </div>
        )}
      </div>
      {messages && users && (
        <Input
          channel={channel}
          sendMessage={handleSendMessage}
          user={user}
          disabled={!channel || channel.type === 'broadcast' || channel.disabled}
        />
      )}
    </div>
  )
}

const MessageList = ({ channelId, messages, user, users, setUserInfo, embedded }) => {
  const openUserInfo = userId => {
    if (userId === CHAT_ADMIN_PROFILE.user_id) return
    if (embedded) {
      window.ReactNativeWebView?.postMessage(`user_info_${userId}`)
    } else {
      setUserInfo(userId)
      window.history.pushState({}, '', `/user/${userId}` + window.location.search)
    }
  }

  return messages?.map((message, index) => {
    const owner = message.user_id == user
    const sender =
      message.user && message.user.name
        ? message.user
        : users.find(user => user.user_id == message.user_id)
    const previousMessage = messages[index - 1]
    const nextMessage = messages.length - 1 == index ? null : messages[index + 1]
    const firstMessageFromSender = !previousMessage || previousMessage.user_id != message.user_id
    const lastMessageFromSender = !nextMessage || nextMessage.user_id != message.user_id
    const sameDay =
      previousMessage &&
      new Date(previousMessage.date).toLocaleDateString() ==
        new Date(message.date).toLocaleDateString()
    return (
      <>
        {!sameDay && <DayDivider day={formatChatDate(message.date)} />}
        <ChatBubble
          key={index}
          owner={owner}
          sender={sender}
          message={message}
          firstFromSender={firstMessageFromSender}
          lastFromSender={lastMessageFromSender}
          openUserInfo={() => openUserInfo(message.user_id)}
          embedded={embedded}
          channelId={channelId}
        />
      </>
    )
  })
}

const DayDivider = ({ day }) => {
  return (
    <div className='w-full flex justify-center sticky top-0'>
      <div className='flex items-center justify-center bg-gray rounded-full w-20 sticky my-4'>
        <p className='text-sm text-white py-1 px-3'>{day}</p>
      </div>
    </div>
  )
}

const DriverPhoneActions = ({ lobbyId, phoneNumber }) => {
  const { user } = useAuth()
  const handleClick = action => {
    Mixpanel.track(`Click ${action} en chat`, {
      Lobby: lobbyId,
      Número: phoneNumber
    })
  }

  return (
    <div className='flex flex-row w-full bg-white p-4'>
      <a
        target='_blank'
        href={`tel:${phoneNumber}`}
        className='w-1/2 flex flex-row items-center gap-2 cursor-pointer justify-center'
        onClick={() => handleClick('Llamar')}
      >
        <LocalPhoneRounded className='text-primary' />
        <p className='text-primary'>Llamar</p>
      </a>
      <a
        target='_blank'
        href={`https://api.whatsapp.com/send/?phone=${phoneNumber}&text=Hola, soy ${
          user[namespace + 'first_name']
        } de tu viaje en Viatik`}
        className='w-1/2 flex flex-row items-center gap-2 cursor-pointer justify-center'
        onClick={() => handleClick('Mandar WPP')}
      >
        <WhatsApp className='text-primary' />
        <p className='text-primary'>Mensaje</p>
      </a>
    </div>
  )
}

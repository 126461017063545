import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: {
      Poppins: 'Poppins, sans-serif'
    }
  },
  shape: {
    borderRadius: 20
  }
})

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: 0,
    borderWidth: 0
  },
  overlay: {
    zIndex: 120,
    backgroundColor: 'rgba(0,0,0,0)'
  }
}

module.exports = {
  blocked_by_user: 'No podés enviar mensajes a {otherUserName} porque te ha bloqueado.',
  blocked_user: 'No podés enviar mensajes a {otherUserName} porque lo has bloqueado.',
  request_rejected: 'No podés enviar mensajes porque la solicitud ha sido rechazada',
  request_cancelled: 'No podés enviar mensajes porque la solicitud ha sido cancelada',
  disabled: 'Podés seguir hablando con {otherUserName} a través del chat grupal del viaje.',
  companion_warning:
    'Evitá pagar cualquier tipo de seña o adelanto. Te volvés vulnerable ante estafas y quedas fuera de la protección de la plataforma.',
  has_to_pay_companion_warning:
    'Evitá pagar fuera de la plataforma. Perderás todas las garantías, te volves vulnerable ante fraudes y puede resultar en la suspensión permanente de tu cuenta.',
  driver_warning:
    'No solicites ni aceptes ningún tipo de seña o adelanto. Esto resultará en la suspensión permanente de tu cuenta.',
  has_to_pay_driver_warning:
    'No solicites ni aceptes pagos fuera de la plataforma. Esto resultará en la suspensión permanente de tu cuenta.'
}

module.exports = {
  MERCADOPAGO_KEY: {
    UYU: process.env.REACT_APP_MERCADOPAGO_KEY_UY,
    ARS: process.env.REACT_APP_MERCADOPAGO_KEY_AR
  },
  UY_BANKS: [
    { value: 'BROU', name: 'BROU' },
    { value: 'Itaú', name: 'Itaú' },
    { value: 'Santander', name: 'Santander' },
    { value: 'Mi Dinero', name: 'Mi Dinero' },
    { value: 'Prex', name: 'Prex' },
    { value: 'BBVA', name: 'BBVA' },
    { value: 'Banco Nación Argentina', name: 'Banco Nación Argentina' },
    { value: 'Citibank', name: 'Citibank' },
    { value: 'HSBC', name: 'HSBC' },
    { value: 'Scotiabank', name: 'Scotiabank' },
    { value: 'Heritage', name: 'Heritage' }
  ],

  ACCOUNT_TYPES: [
    { value: 'savings', name: 'Caja de ahorro' },
    { value: 'checking', name: 'Cuenta corriente' },
    { value: 'cashier', name: 'Cuenta cajero' }
  ]
}

import Locations from './Locations'
import Info from './Info'

const LobbyCard = ({ lobby, full, userCard }) => {
  const origin = lobby.Journey.start_name
    ? lobby.Journey.start_name.locality
    : lobby.Journey.origin_name.locality
  const destination = lobby.Journey.end_name
    ? lobby.Journey.end_name.locality
    : lobby.Journey.destination_name.locality

  return (
    <>
      <Locations
        originName={origin}
        destinationName={destination}
        style={{
          color: '#c65944',
          fontSize: '1.4rem'
        }}
        ownerName={lobby.Owner_info.first_name}
        ownerPicture={lobby.Owner_info.picture}
        verified={lobby.Owner_info.verified}
        full={full}
        stars={lobby.Owner_info.stars}
      />
      <Info
        userCard={userCard}
        time={lobby.Journey.departure_time}
        price={lobby.price}
        full={full}
        discount={0}
        currency={lobby.currency}
        autoBooking={lobby.auto_booking}
      />
    </>
  )
}

export default LobbyCard

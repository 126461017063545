import { ThemeProvider, Divider } from '@mui/material'
import theme from './theme'
import { ChevronRightRounded } from '@mui/icons-material'
import { Application, Calendar } from 'react-rainbow-components'
import TimePicker from '../../commons/TimePicker'
import { Mixpanel } from '../../Mixpanel'
import NextButton from './NextButton'
import BackButton from '../../commons/BackButton'
import Spinner from '../../commons/Spinner'

const Return = ({
  handleSubmit,
  departureDate,
  selectedReturnDate,
  selectedReturnTime,
  setSelectedReturnTime,
  setSelectedReturnDate,
  returnSeats,
  setReturnSeats,
  onBack,
  step,
  setStep
}) => {
  const rainbowTheme = {
    rainbow: {
      palette: {
        brand: '#f25c45'
      }
    }
  }

  const handleBack = () => {
    if (step == 0) {
      onBack()
    } else {
      setStep(step - 1)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <BackButton handleBack={handleBack} />
      <div className='flex flex-col px-6 h-[93vh] narrow:h-[92vh] justify-start sm:items-center'>
        <div className='sm:w-1/2 grow overflow-auto py-6'>
          {step == 0 ? (
            <>
              <div className='text-2xl text-start mb-12'>¿Planeás hacer un viaje de vuelta?</div>
              <div className='flex flex-col justify-center'>
                <div className='flex flex-col'>
                  <div
                    onClick={() => {
                      Mixpanel.track('Viaje de vuelta - Si')
                      setStep(1)
                    }}
                    className='flex flex-row w-full justify-between items-center cursor-pointer py-8'
                  >
                    <p className='text-light_coral text-start'>Publicar viaje de retorno</p>
                    <ChevronRightRounded className='text-gray' />
                  </div>
                  <Divider sx={{ margin: 0 }} />
                  <div
                    onClick={() => {
                      Mixpanel.track('Viaje de vuelta - No')
                      handleSubmit(false)
                    }}
                    className='flex flex-row w-full justify-between items-center cursor-pointer py-8'
                  >
                    <p className='text-start text-base text-gray'>No publicar viaje de retorno</p>
                    <ChevronRightRounded className='text-gray' />
                  </div>
                </div>
              </div>
            </>
          ) : step == 1 ? (
            <>
              <div className='text-2xl text-start mb-12'>Elige la fecha de regreso</div>
              <Application theme={rainbowTheme}>
                <Calendar
                  minDate={toDate(departureDate)}
                  value={selectedReturnDate}
                  onChange={date => {
                    setSelectedReturnDate(date)
                    setStep(2)
                  }}
                />
              </Application>
              <NextButton onSubmit={() => setStep(2)} disabled={!selectedReturnDate} />
            </>
          ) : step == 2 ? (
            <>
              <div className='text-2xl text-start mb-6'>Hora de tu regreso</div>
              <TimePicker
                data={selectedReturnTime}
                setData={setSelectedReturnTime}
                style={{ fontSize: 40, alignText: 'center', justifyContent: 'center' }}
              />
              <NextButton onSubmit={() => setStep(3)} />
            </>
          ) : (
            step === 3 && (
              <>
                <div className='text-2xl text-start mb-12'>Asientos disponibles en tu regreso</div>
                <Spinner
                  setNumber={setReturnSeats}
                  number={returnSeats}
                  label='Asientos'
                  min={1}
                  max={4}
                  step={1}
                />
                <NextButton onSubmit={() => handleSubmit(true)} />
              </>
            )
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

const toDate = dateString => {
  const date = new Date(dateString)
  return new Date(date.getTime() + 3 * 1000 * 60 * 60)
}

export default Return

import { TextField } from '@mui/material'

export default ({ value, onChange, placeholder, type }) => {
  return (
    <TextField
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      fullWidth
      type={type ?? 'text'}
      variant='filled'
      InputProps={{
        disableUnderline: true,
        style: { borderRadius: '0.5rem' },
        inputProps: {
          style: {
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '24px',
            paddingRight: '24px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            backgroundColor: '#f3f4f6',
            borderRadius: '0.5rem'
          }
        }
      }}
    />
  )
}

import { formatExpiration } from '../../utils'

export default ({ companion, owner }) => {
  return (
    <div className='flex flex-col gap-4 px-6 text-base text-white bg-low mx-6 mb-2 rounded-[10px] py-3'>
      <p>Tu solicitud está esperando la aprobación del conductor</p>
      <div className='flex flex-row items-center gap-2'>
        <img src={require('../../assets/img/join-requested.png')} className='w-5 h-5' />
        <p className='text-sm'>
          {owner} responderá antes de las {formatExpiration(companion.expiration)}
        </p>
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import Button from '../../commons/Button'

export default ({ free }) => {
  const carHorn = new Audio(require('../../assets/audio/car_horn.mp3'))

  useEffect(() => {
    carHorn.volume = 0.1
    carHorn.play().catch(error => {
      document.addEventListener(
        'click',
        () => {
          carHorn.play()
        },
        { once: true }
      )
    })
  }, [])

  const handleSubmit = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('embedded')) {
      window.open('viatik:///request-success')
    } else {
      window.location.href = window.location.origin + '/trips'
    }
  }

  return (
    <div className='flex flex-col justify-between h-full py-10 bg-low'>
      <div className='flex flex-col gap-9 items-center justify-center px-8'>
        <div className='flex bg-white rounded-full w-32 h-32 items-center justify-center'>
          <img
            src={require('../../assets/img/join-requested-primary.png')}
            alt='pendiente'
            className='w-14'
          />
        </div>
        <div className='flex flex-col gap-6 items-center justify-center text-white'>
          <p className='text-lg font-bold text-center'>
            Tu solicitud está esperando la aprobación del conductor
          </p>
          <p className='text-base text-center'>
            {free
              ? 'Tu reserva será confirmada cuando el conductor apruebe tu solicitud.'
              : 'Si tu solicitud es rechazada, recibirás el reembolso total de tu pago.'}
          </p>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <Button
          name='Ver solicitud'
          style={{ margin: 'auto', fontSize: 16, backgroundColor: 'white', color: '#d65944' }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

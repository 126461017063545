import { useState, useEffect } from 'react'
import { ThemeProvider, Divider, Checkbox } from '@mui/material'
import BackButton from '../../commons/BackButton'
import theme from './theme'
import { useAuthorizedFetch } from '../../hooks'
import apiConfig from '../../config/Api'
import { Dots } from 'react-activity'
import 'react-activity/dist/Dots.css'
import NextButton from './NextButton'
import LocationInput from '../../commons/LocationInput'
import SlideModal from '../../commons/SlideModal'
import BlockedRoute from '../BlockedRoute'
import { Mixpanel } from '../../Mixpanel'

const Stops = ({ handleSubmit, stops, setStops, params, navigate }) => {
  const { loading, response } = useAuthorizedFetch(
    `${apiConfig.uri}/locations/stops?${new URLSearchParams(params)}`
  )
  const [addStop, setAddStop] = useState(false)
  const [stopsList, setStopsList] = useState([])
  const [blockedRoute, setBlockedRoute] = useState(false)

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setStopsList(data)
      })
    } else if (response.status === 404) {
      response.json().then(data => {
        Mixpanel.track('Ruta bloqueada - Publicar', {
          Origen: params.origin_name,
          Destino: params.destination_name
        })
        setBlockedRoute(data)
      })
    }
  }, [loading])

  return (
    <ThemeProvider theme={theme}>
      {!addStop && <BackButton handleBack={() => navigate('destination')} />}
      <div className='flex flex-col h-[95vh] overflow-auto px-6 text-2xl pt-6 text-xl text-start'>
        {blockedRoute ? (
          <BlockedRoute
            searchData={{
              originName: params.origin_name,
              destinationName: params.destination_name
            }}
            content={blockedRoute}
            type={'Publicar'}
          />
        ) : (
          <>
            <div>
              <div className='mb-6 text-2xl'>
                Agrega paradas en las que los acompañantes puedan unirse
              </div>
              <div className='text-base text-gray mb-6'>
                El precio por acompañante será adaptado al trayecto
              </div>
            </div>
            <>
              {loading ? (
                <div className='flex flex-col justify-center items-center'>
                  <Dots color='#d65944' />
                </div>
              ) : (
                <>
                  {stopsList.length > 0 ? (
                    stopsList.map(x => (
                      <>
                        <div
                          onClick={() => {
                            if (stops.map(y => y.location_name).includes(x.location_name)) {
                              setStops(stops.filter(y => y.location_name !== x.location_name))
                            } else {
                              setStops([...stops, x])
                            }
                          }}
                          className='flex flex-row mb-5 mt-5 text-base cursor-pointer items-center ml-[-10px]'
                        >
                          <Checkbox
                            checked={stops.map(y => y.location_name).includes(x.location_name)}
                            key={x.location_name}
                            value={x.location_name}
                            sx={{ '&.Mui-checked': { color: '#f25c45' } }}
                            size={'small'}
                          />
                          <p>{x.location_name}</p>
                        </div>
                        <Divider />
                      </>
                    ))
                  ) : (
                    <div className='flex flex-col justify-center items-center'>
                      <div className='text-base text-gray'>
                        No hay paradas disponibles para este trayecto. Podés agregar las tuyas.
                      </div>
                    </div>
                  )}
                  <div
                    className='cursor-pointer text-light_coral text-base text-start pt-6 pb-10'
                    onClick={() => setAddStop(true)}
                  >
                    Agregar parada
                  </div>
                </>
              )}
            </>
            <NextButton
              onSubmit={() => {
                handleSubmit()
              }}
              disabled={loading}
            />
          </>
        )}
      </div>
      <SlideModal fullScreen open={addStop} onClose={() => setAddStop(false)}>
        <StopInput
          setSelectedStops={setStops}
          setStops={setStopsList}
          selectedStops={stops}
          setAddStop={setAddStop}
        />
      </SlideModal>
    </ThemeProvider>
  )
}

export default Stops

const StopInput = ({ setStops, setSelectedStops, selectedStops, setAddStop }) => {
  const [text, setText] = useState('')

  const handleBack = (location, locationName) => {
    if (location && locationName) {
      const newStop = {
        location_name: locationName.locality,
        location: location.lat + ', ' + location.lng
      }
      if (!selectedStops.map(y => y.location_name).includes(newStop.location_name)) {
        setSelectedStops([...selectedStops, newStop])
        setStops(prev => [...prev, newStop])
      }
    }
    setAddStop(false)
  }

  return (
    <div className='pt-6'>
      <LocationInput
        handleBack={handleBack}
        setText={setText}
        text={text}
        placeholder={'Agregá una parada'}
      />
    </div>
  )
}

import { InfoOutlined, WarningAmberRounded } from '@mui/icons-material'
import chat from '../../content/chat'
import { formatText } from '../../utils'

export default ({ channel, disabled, user }) => {
  if (!channel) return <></>

  return (
    <>
      {channel.type === 'direct-message' && (
        <Container disabled={disabled}>
          <Icon channel={channel} disabled={disabled} />
          <DirectMessageText channel={channel} disabled={disabled} user={user} />
        </Container>
      )}
      {channel.type === 'lobbygroup' && !channel.has_to_pay && channel.country !== 'uy' && (
        <Container disabled={disabled}>
          <WarningAmberRounded sx={{ color: '#ef4444' }} />
          <LobbyGroupText channel={channel} user={user} />
        </Container>
      )}
    </>
  )
}

const Container = ({ children, disabled }) => {
  return (
    <div
      className={`flex flex-row items-center justify-center px-4 pt-1 pb-2 gap-2 bg-light_gray 
        ${disabled && 'pb-[5.5rem]'}`}
    >
      {children}
    </div>
  )
}

const Icon = ({ channel, disabled }) => {
  const iconColor = disabled ? '#ff6700' : '#ef4444'

  if (channel.request_status === 'rejected' || channel.request_status === 'cancelled') {
    return <InfoOutlined sx={{ color: '#9ca3af' }} />
  }

  return <WarningAmberRounded sx={{ color: iconColor }} />
}

const DirectMessageText = ({ channel, disabled, user }) => {
  const blockedUser = channel.users.find(u => u.blocked)
  const otherUserName = channel.users?.filter(u => u.user_id !== user)?.[0]?.name
  const userRole = channel?.users?.find(u => u.user_id === user)?.role

  if (blockedUser?.user_id === user) {
    return (
      <p className='text-sm text-warning'>{formatText(chat.blocked_by_user, { otherUserName })}</p>
    )
  }

  if (blockedUser) {
    return (
      <p className='text-sm text-warning'>{formatText(chat.blocked_user, { otherUserName })}</p>
    )
  }

  if (channel.request_status === 'rejected') {
    return <p className='text-sm text-gray'>{chat.request_rejected}</p>
  }

  if (channel.request_status === 'cancelled') {
    return <p className='text-sm text-gray'>{chat.request_cancelled}</p>
  }

  if (disabled) {
    return <p className='text-sm text-warning'>{formatText(chat.disabled, { otherUserName })}</p>
  }

  const companionWarning =
    (typeof channel.has_to_pay === 'boolean' && channel.has_to_pay) || channel.country === 'uy'
      ? chat.has_to_pay_companion_warning
      : chat.companion_warning

  const driverWarning =
    (typeof channel.has_to_pay === 'boolean' && channel.has_to_pay) || channel.country === 'uy'
      ? chat.has_to_pay_driver_warning
      : chat.driver_warning

  return (
    <p className='text-xs text-error'>
      {userRole === 'companion' ? companionWarning : driverWarning}
    </p>
  )
}

const LobbyGroupText = ({ channel, user }) => {
  const userRole = channel.users.find(u => u.user_id === user)?.role

  return (
    <p className='text-xs text-error'>
      {userRole === 'companion' ? chat.companion_warning : chat.driver_warning}
    </p>
  )
}

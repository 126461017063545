import { useEffect, useState } from 'react'
import AutocompleteBox from '../../commons/AutocompleteBox'
import NextButton from './NextButton'
import { getCurrency } from '../../utils'
import { ChevronRightRounded } from '@mui/icons-material'
import { Divider } from '@mui/material'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { useLocalizationContext } from '../../contexts/LocalizationContext'

export default ({
  setLocation,
  setLocationName,
  locationName,
  handleSelect,
  type,
  lastCreatedLobbies,
  loadingLastCreatedLobbies,
  handleLastCreatedLobby
}) => {
  const { config } = useLocalizationContext()
  const [text, setText] = useState('')

  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div className='sm:w-1/2'>
        {type === 'origin' && (
          <p className='font-bold text-primary text-start mb-6'>Publicá tu viaje como conductor</p>
        )}
        <p className='text-start mb-6'>
          {type === 'origin' ? '¿De donde salís?' : '¿A donde vas?'}
        </p>
        <AutocompleteBox
          placeholder={`Barrio o ciudad de ${type === 'origin' ? 'salida' : 'destino'}`}
          setLocation={setLocation}
          setLocationName={setLocationName}
          locationName={locationName}
          text={text}
          setText={setText}
          handleSelect={handleSelect}
        />
        <p className='text-start text-sm mt-3 ml-1 text-gray'>
          {type === 'origin'
            ? config?.location_example.origin
            : config?.location_example.destination}
        </p>
        {lastCreatedLobbies && (
          <div className='flex flex-col mt-6'>
            <div className='flex items-center gap-3'>
              <p className='text-start text-lg'>Repetir viaje</p>
              {loadingLastCreatedLobbies && <LoadingIndicator size={12} />}
            </div>
            {lastCreatedLobbies.length > 0
              ? lastCreatedLobbies.map((lobby, index) => (
                  <>
                    <LastLobbyCard
                      key={index}
                      lobby={lobby}
                      onClick={() => handleLastCreatedLobby(lobby)}
                    />
                    <Divider />
                  </>
                ))
              : !loadingLastCreatedLobbies && (
                  <p className='text-start text-gray text-sm mt-3'>
                    Tus últimos viajes apareceran aquí
                  </p>
                )}
          </div>
        )}
        {locationName && <NextButton onSubmit={() => handleSelect(locationName)} />}
      </div>
    </div>
  )
}

const LastLobbyCard = ({ lobby, onClick }) => {
  const { Journey } = lobby

  return (
    <div
      className='flex flex-row w-full items-center justify-between cursor-pointer'
      onClick={onClick}
    >
      <div className='flex flex-col text-start text-sm gap-2 py-3'>
        <p>
          {Journey.origin_name.locality} - {Journey.destination_name.locality}
        </p>
        <p className='text-gray'>{lobby.seats_available} asientos disponibles</p>
        <p className='text-gray'>
          {getCurrency(lobby.currency)} {lobby.price}
        </p>
      </div>
      <ChevronRightRounded className='text-gray' />
    </div>
  )
}

import { useRef, useState } from 'react'
import { ThemeProvider, IconButton, Divider } from '@mui/material'
import { ArrowBackIosNew, ChevronRightRounded } from '@mui/icons-material'
import theme from './theme'
import { Mixpanel } from '../../Mixpanel'
import { containsContactInfo } from '../../utils'
import Spinner from '../../commons/Spinner'
import NextButton from './NextButton'
import BackButton from '../../commons/BackButton'

const Seats = ({ seats, setSeats, handleSubmit, handleBack }) => {
  const [middleSeatStep, setMiddleSeatStep] = useState('false')

  const onSubmit = () => {
    if (middleSeatStep == 'false' && seats >= 3) {
      setMiddleSeatStep('show')
    } else {
      handleSubmit()
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <BackButton
        handleBack={middleSeatStep == 'show' ? () => setMiddleSeatStep('false') : handleBack}
      />
      {middleSeatStep == 'show' ? (
        <div className='flex flex-col px-6 mt-6 overflow-auto sm:items-center'>
          <div className='sm:w-1/2'>
            <p className='text-start mb-3 text-2xl'>Considerá la comodidad</p>
            <div className='text-start mb-4 text-lg text-gray'>
              Tus acompañantes viajaran más cómodos con un asiento vacío entre ellos
            </div>
            <div className='flex h-full flex-col justify-center'>
              <div className='flex flex-col'>
                <div
                  onClick={() => {
                    Mixpanel.track('Asiento Vacío - Si')
                    setMiddleSeatStep(false)
                  }}
                  className='flex flex-row w-full justify-between items-center cursor-pointer py-8'
                >
                  <p className='text-light_coral text-start'>Lo quiero cambiar</p>
                  <ChevronRightRounded className='text-gray' />
                </div>
                <Divider sx={{ margin: 0 }} />
                <div
                  onClick={() => {
                    Mixpanel.track('Asiento Vacío - No')
                    handleSubmit()
                  }}
                  className='flex flex-row w-full justify-between items-center cursor-pointer py-8'
                >
                  <p className='text-start'>Esta bien así</p>
                  <ChevronRightRounded className='text-gray' />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
          <div className='sm:w-1/2'>
            <div>
              <div className='text-start mb-12 text-2xl'>¿Cuantos asientos tienes disponibles?</div>
              <Spinner
                setNumber={setSeats}
                number={seats}
                label='Asientos'
                min={1}
                max={4}
                step={1}
                style={{ fontSize: 30 }}
              />
            </div>
          </div>
          <NextButton onSubmit={onSubmit} />
        </div>
      )}
    </ThemeProvider>
  )
}

export default Seats

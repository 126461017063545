import Modal from 'react-modal'
import Button from '../Button'
import { Mixpanel } from '../../Mixpanel'
import styles from './styles'

const Alert = ({ children, visible, onClose, onSubmit, hideButton, name, fullscreen }) => {
  return (
    <Modal
      isOpen={visible}
      style={fullscreen ? styles.fullScreen : styles.alert}
      contentLabel='Alert'
      onRequestClose={() => {
        Mixpanel.track('Cerrar alerta')
        onClose()
      }}
    >
      <div className='h-full flex flex-col justify-around'>
        <div className='text-center text-lg mb-4'>{children}</div>
        {onSubmit ? (
          <div className='flex gap-3'>
            <Button
              visible={visible}
              name={'Aceptar'}
              style={{ marginBottom: 15, zIndex: 300, fontSize: '21px' }}
              onClick={() => {
                Mixpanel.track('Aceptar alerta')
                onSubmit()
                onClose()
              }}
            />
            <Button
              visible={visible}
              name={'Cancelar'}
              style={{
                marginBottom: 15,
                zIndex: 300,
                backgroundColor: 'gray',
                fontSize: '16px'
              }}
              onClick={() => {
                Mixpanel.track('Cerrar alerta')
                onClose()
              }}
            />
          </div>
        ) : (
          <div className='w-full text-center' hidden={hideButton}>
            <Button
              visible={visible}
              name={name ? name : 'Aceptar'}
              style={
                name
                  ? {
                      zIndex: 300,
                      backgroundColor: 'gray',
                      fontSize: '16px'
                    }
                  : { marginBottom: 15, zIndex: 300, fontSize: '16px' }
              }
              onClick={() => {
                Mixpanel.track('Aceptar alerta')
                onClose()
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default Alert

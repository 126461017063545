import { Divider, IconButton } from '@mui/material'
import { ChevronRightRounded, CloseRounded } from '@mui/icons-material'
import NextButton from './NextButton'
import BackButton from '../../commons/BackButton'
import { useEffect, useState } from 'react'
import { Mixpanel } from '../../Mixpanel'
import SlideModal from '../../commons/SlideModal'
import Input from '../../commons/Input'
import { useAuth } from '../../hooks'
import { namespace } from '../../config/Auth0'
import Snackbar from '../../commons/Snackbar'
import { ACCOUNT_TYPES, UY_BANKS } from '../../config/Payment'

const BANK_ACCOUNT_LENGTHS = {
  BROU: 14,
  Itaú: 7,
  Scotiabank: 10,
  BBVA: 9,
  Citibank: 10,
  Heritage: 9
}

const PaymentMethod = ({ setBankAccount, bankAccount, onBack, handleSubmit, currency }) => {
  const [step, setStep] = useState(bankAccount ? 2 : 1)

  const handleBack = () => {
    step === 1 ? onBack() : setStep(1)
  }

  const onSubmit = bankInfo => {
    if (currency === 'UYU') {
      const formattedBankAccount = {
        owner_first_name: bankInfo.firstName.trim(),
        owner_last_name: bankInfo.lastName.trim(),
        owner_id_number: bankInfo.idNumber.trim(),
        bank_name: bankInfo.bank,
        account_number: bankInfo.accountNumber.trim(),
        account_type: bankInfo.type,
        currency: 'UYU'
      }
      setBankAccount(formattedBankAccount)
    } else {
      setBankAccount({
        owner_first_name: bankInfo.firstName.trim(),
        owner_last_name: bankInfo.lastName.trim(),
        owner_id_number: bankInfo.idNumber.trim(),
        account_number: bankInfo.accountNumber.trim(),
        account_type: bankInfo.type,
        currency: 'ARS'
      })
    }
    handleSubmit()
  }

  return (
    <>
      <BackButton handleBack={handleBack} />
      <div className='flex flex-col px-6 h-[93vh] narrow:h-[92vh] justify-start sm:items-center'>
        {step === 1 ? (
          <IntroStep
            onContinue={() => {
              Mixpanel.track('Agregar datos de cuenta')
              setStep(2)
            }}
            onSkip={() => {
              Mixpanel.track('No agregar datos de cuenta')
              setBankAccount(null)
              handleSubmit()
            }}
          />
        ) : (
          <BankInfo handleSubmit={onSubmit} bankAccount={bankAccount} currency={currency} />
        )}
      </div>
    </>
  )
}

const IntroStep = ({ onContinue, onSkip }) => (
  <div className='sm:w-1/2 grow overflow-auto py-6'>
    <div className='flex items-center justify-center mb-2'>
      <img src={require('../../assets/img/payment_success.png')} className='w-1/2 sm:w-1/3' />
    </div>
    <p className='text-start mb-8 text-xl'>
      Al terminar el viaje, vas a recibir el dinero en tu cuenta bancaria.
    </p>
    <div className='flex flex-col justify-center'>
      <div className='flex flex-col'>
        <div
          onClick={() => {
            Mixpanel.track('Metodo de pago - Si')
            onContinue()
          }}
          className='flex flex-row w-full justify-between items-center cursor-pointer py-8'
        >
          <p className='text-light_coral text-start'>Agregar los datos de tu cuenta ahora</p>
          <ChevronRightRounded className='text-gray' />
        </div>
        <Divider sx={{ margin: 0 }} />
        <div
          onClick={() => {
            Mixpanel.track('Metodo de pago - No')
            onSkip()
          }}
          className='flex flex-row w-full justify-between items-center cursor-pointer py-8'
        >
          <p className='text-start text-base text-gray'>Agregarlo luego</p>
          <ChevronRightRounded className='text-gray' />
        </div>
      </div>
    </div>
  </div>
)

const BankInfo = ({ handleSubmit, bankAccount, currency }) => {
  const { user } = useAuth()
  const [accountTypes, setAccountTypes] = useState(ACCOUNT_TYPES)
  const [bank, setBank] = useState(UY_BANKS.find(b => b.value === bankAccount?.bank_name) || null)
  const [accountNumber, setAccountNumber] = useState(bankAccount?.account_number || '')
  const [type, setType] = useState(
    ACCOUNT_TYPES.find(t => t.value === bankAccount?.account_type) || ACCOUNT_TYPES[0]
  )
  const [firstName, setFirstName] = useState(user[namespace + 'first_name'] || '')
  const [lastName, setLastName] = useState(user[namespace + 'last_name'] || '')
  const [idNumber, setIdNumber] = useState(bankAccount?.owner_id_number || '')
  const [bankModal, setBankModal] = useState(false)
  const [typeModal, setTypeModal] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = () => {
    if (currency === 'UYU') {
      if (validateAccountNumber(accountNumber, bank.value)) {
        handleSubmit({
          firstName: firstName,
          lastName: lastName,
          idNumber: idNumber,
          bank: bank.value,
          accountNumber: formatNumber(accountNumber),
          type: type.value
        })
      } else {
        setError('Numero de cuenta invalido')
      }
    } else {
      handleSubmit({
        firstName: firstName,
        lastName: lastName,
        idNumber: idNumber,
        accountNumber: accountNumber,
        type: type.value
      })
    }
  }

  useEffect(() => {
    if (['Prex', 'Mi Dinero'].includes(bank?.value)) {
      setAccountTypes(ACCOUNT_TYPES.filter(type => type.value === 'savings'))
    } else if (bank?.value === 'BBVA') {
      setAccountTypes(ACCOUNT_TYPES)
    } else {
      setAccountTypes(ACCOUNT_TYPES.filter(type => type.value !== 'cashier'))
    }
  }, [bank])

  return (
    <div className='sm:w-1/2 grow overflow-auto py-6'>
      <p className='text-start mb-4 text-xl'>Datos de la cuenta</p>
      <p className='text-start mb-8 text-sm text-gray'>
        {currency === 'UYU'
          ? 'Ingresar el número de cuenta para transferencias a otros bancos'
          : 'Ingresá los datos asociados a tu cuenta'}
      </p>

      {currency === 'UYU' ? (
        <>
          <div className='flex flex-col gap-6'>
            <Input value={firstName} onChange={setFirstName} placeholder='Nombre' />
            <Input value={lastName} onChange={setLastName} placeholder='Apellido' />
            <Input value={idNumber} onChange={setIdNumber} placeholder='CI' type='number' />
            <BankSelector bank={bank} onSelect={() => setBankModal(true)} />
            <Input
              value={accountNumber}
              onChange={setAccountNumber}
              placeholder='Nro. de cuenta'
              type='number'
            />
            <AccountTypeSelector type={type} onSelect={() => setTypeModal(true)} />
          </div>
          <SelectModal
            items={accountTypes}
            handleSelect={item => {
              setType(item)
              setTypeModal(false)
            }}
            open={typeModal}
            onClose={() => setTypeModal(false)}
          />
          <SelectModal
            items={UY_BANKS}
            handleSelect={item => {
              setBank(item)
              setBankModal(false)
            }}
            open={bankModal}
            onClose={() => setBankModal(false)}
          />
        </>
      ) : (
        <>
          <div className='flex flex-col gap-6'>
            <Input value={firstName} onChange={setFirstName} placeholder='Nombre' />
            <Input value={lastName} onChange={setLastName} placeholder='Apellido' />
            <Input value={idNumber} onChange={setIdNumber} placeholder='CUIL' type='number' />
            <Input value={accountNumber} onChange={setAccountNumber} placeholder='CVU/CBU' />
            <AccountTypeSelector type={type} onSelect={() => setTypeModal(true)} />
          </div>
          <SelectModal
            items={accountTypes}
            handleSelect={item => {
              setType(item)
              setTypeModal(false)
            }}
            open={typeModal}
            onClose={() => setTypeModal(false)}
          />
        </>
      )}
      <Snackbar message={error} setMessage={setError} type='error' />
      <NextButton
        disabled={
          currency === 'UYU'
            ? !firstName.trim() ||
              !lastName.trim() ||
              idNumber.trim().length < 7 ||
              !bank ||
              formatNumber(accountNumber).length < 4 ||
              !type
            : accountNumber.trim().length !== 22 ||
              !firstName.trim() ||
              !lastName.trim() ||
              idNumber.trim().length !== 11
        }
        onSubmit={onSubmit}
      />
    </div>
  )
}

const BankSelector = ({ bank, onSelect }) => (
  <div className='py-3 px-6 bg-light_gray rounded-lg cursor-pointer' onClick={onSelect}>
    {bank ? (
      <p className='text-start text-base'>{bank.name}</p>
    ) : (
      <p className='text-start text-base text-gray'>Banco</p>
    )}
  </div>
)

const AccountTypeSelector = ({ type, onSelect }) => (
  <div className='py-3 px-6 bg-light_gray rounded-lg cursor-pointer mb-24' onClick={onSelect}>
    {type ? (
      <p className='text-start text-base'>{type.name}</p>
    ) : (
      <p className='text-start text-base text-gray'>Tipo de cuenta</p>
    )}
  </div>
)

const SelectModal = ({ handleSelect, items, open, onClose }) => (
  <SlideModal fullScreen open={open} onClose={onClose}>
    <div className='flex justify-end pt-4 px-4'>
      <IconButton onClick={onClose} sx={{ color: '#d65944' }}>
        <CloseRounded sx={{ fontSize: '26px' }} />
      </IconButton>
    </div>
    <div className='flex flex-col pt-4 pb-6 px-6 overflow-auto'>
      {items.map((item, index) => (
        <>
          <div
            className='flex flex-row justify-between items-center cursor-pointer py-6'
            key={item.value}
            onClick={() => handleSelect(item)}
          >
            <p className='text-start text-base'>{item.name}</p>
            <ChevronRightRounded className='text-gray' />
          </div>
          {index !== items.length - 1 && <Divider sx={{ margin: 0 }} />}
        </>
      ))}
    </div>
  </SlideModal>
)

const validateAccountNumber = (accountNumber, bank) => {
  const requiredLength = BANK_ACCOUNT_LENGTHS[bank]
  if (requiredLength) {
    return bank === 'BBVA'
      ? accountNumber.length <= requiredLength
      : accountNumber.length === requiredLength
  }
  return accountNumber.length >= 4
}

const formatNumber = number => number.replace(/\D/g, '')

export default PaymentMethod

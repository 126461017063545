import { useState } from 'react'
import BusCard from './BusCard'
import LobbyCard from './LobbyCard'
import { useAuth } from '../../hooks'
import Lottie from 'lottie-react'

const Card = ({ item, onPress, userCard }) => {
  const { user } = useAuth()
  const [isPressed, setIsPressed] = useState(false)
  const full = item.seats_remaining === 0 && !userCard

  const handleMouseDown = () => {
    if (!full) {
      setIsPressed(true)
    }
  }

  const handleMouseUp = () => {
    if (!full) {
      setIsPressed(false)
    }
  }

  const handleClick = () => {
    if (!full) {
      onPress(item)
    }
  }

  return (
    <div
      className={`pt-5 pb-5 px-4
                lg:mx-60 lg:pl-12 lg:pr-12 cursor-pointer rounded-xl shadow-[0px_0px_5px_0px_rgba(0,0,0,0.2)] my-4
                ${isPressed && 'opacity-50'}
                ${full && 'cursor-auto'}`}
    >
      {userCard && item.pending_companions.some(companion => companion.user_id === user?.sub) && (
        <Header
          text='Esperando aprobación'
          icon={
            <img
              src={require('../../assets/img/join-requested-primary.png')}
              alt='pendiente'
              className='w-6'
            />
          }
          color='text-primary'
        />
      )}
      {userCard && item.Owner_info.id === user?.sub && item.pending_companions.length > 0 && (
        <Header
          text='Nueva solicitud de reserva'
          icon={
            <Lottie
              animationData={require('../../assets/animations/bell.json')}
              autoplay
              loop={true}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
              style={{ width: 35, height: 35 }}
            />
          }
          color='text-primary'
        />
      )}
      <div
        className={`flex justify-between text-lg h-52`}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => setIsPressed(false)}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
      >
        {item.Owner_info ? (
          <LobbyCard lobby={item} full={full} userCard={userCard} />
        ) : (
          <BusCard bus={item} full={full} />
        )}
      </div>
    </div>
  )
}

const Header = ({ text, icon, color }) => {
  return (
    <div className={`flex items-center mb-6 gap-4 pl-1`}>
      {icon}
      <div className={`text-base ${color}`}>{text}</div>
    </div>
  )
}

export default Card

import { AccessTimeFilled, CalendarToday } from '@mui/icons-material'
import { getCurrency } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons'

const Info = ({ time, price, full, discount, currency, userCard, autoBooking }) => {
  const departureTime = time.substring(11, 16)
  const date = time.substring(8, 10) + '/' + time.substring(5, 7)

  return (
    <div className='flex flex-col justify-around items-end text-[1.2rem]'>
      {full ? (
        <div className='flex gap-2 h-[100%] text-[1.1rem]'>
          <p className='font-black text-[1.4rem]'>Lleno</p>
        </div>
      ) : (
        <>
          <div className='h-[33%]'>
            <div className='flex gap-2 items-center'>
              <p className='text-[1rem]'>{departureTime}</p>
              <AccessTimeFilled style={{ color: '#c65944', fontSize: '1.4rem' }} />
            </div>
          </div>
          <div className='flex flex-row gap-2 h-[33%] text-[1rem]'>
            {!userCard ? (
              <>
                {discount > 0 && (
                  <p className='text-sm text-[#9ca3af] line-through'>
                    {getCurrency(currency)}
                    {price}
                  </p>
                )}
                <p>{price - discount}</p>
                <p className='text-[#c65944] font-black text-[1rem]'>{getCurrency(currency)}</p>
              </>
            ) : (
              <>
                <p>{date}</p>
                <CalendarToday style={{ color: '#c65944', fontSize: '1.4rem' }} />
              </>
            )}
          </div>
          <div className='h-[34%] flex items-center pr-2'>
            {!userCard && autoBooking && (
              <FontAwesomeIcon
                icon={faBoltLightning}
                style={{ color: '#c65944', fontSize: '1.4rem' }}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default Info

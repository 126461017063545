import { isoDateToWeekday } from '../../utils'
import Card from '../Card'

const CardsList = ({ items, handleLobbyCardPress, handleBusCardPress }) => {
  const handlePress = item => {
    if (item.Owner_info) {
      handleLobbyCardPress(item)
    } else {
      handleBusCardPress(item)
    }
  }

  return (
    <>
      {Object.keys(items).map(date => (
        <>
          <p className='text-xl text-primary mb-3 mt-6 text-start sm:text-center'>
            {isoDateToWeekday(date)}
          </p>
          {items[date].map(item => (
            <Card item={item} onPress={() => handlePress(item)} />
          ))}
        </>
      ))}
    </>
  )
}

export default CardsList

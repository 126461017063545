import { endOfDay } from '../../utils'
import Modal from '../../commons/Modal'
import LobbiesAndBusesList from '../LobbiesAndBusesList'
import { useEffect, useState } from 'react'
import CardsList from '../../commons/CardsList'
import ScheduleModal from '../ScheduleModal'
import LobbyInfo from '../../commons/LobbyInfo'
import { Mixpanel } from '../../Mixpanel'
import ScheduleButton from '../ScheduleButton'
import BusInfo from '../../commons/BusInfo'

const SearchResults = ({ visible, setVisible, searchData, initialValues, setDisabledMenu }) => {
  const [scheduleModal, setScheduleModal] = useState(false)
  const [pastLobbies, setPastLobbies] = useState()
  const [futureLobbies, setFutureLobbies] = useState()
  const [selectedLobby, setSelectedLobby] = useState()
  const [selectedBus, setSelectedBus] = useState()

  const dataToFetchLobbies = {
    origin: searchData.origin.lat + ',' + searchData.origin.lng,
    destination: searchData.destination.lat + ',' + searchData.destination.lng,
    origin_name: searchData.originName.locality,
    destination_name: searchData.destinationName.locality,
    origin_country_code: searchData.originName.country_code,
    destination_country_code: searchData.destinationName.country_code,
    min_time: searchData.selectedDate,
    max_time: endOfDay(searchData.selectedDate),
    seats: searchData.seats
  }

  const dataToFetchBuses = {
    origin: searchData.originName.locality,
    destination: searchData.destinationName.locality,
    origin_country_code: searchData.originName.country_code,
    destination_country_code: searchData.destinationName.country_code,
    min_time: searchData.selectedDate,
    max_time: endOfDay(searchData.selectedDate),
    origin_coordinates: searchData.origin.lat + ',' + searchData.origin.lng,
    destination_coordinates: searchData.destination.lat + ',' + searchData.destination.lng
  }

  const handleClose = () => {
    if (pastLobbies) {
      setPastLobbies(null)
    } else if (futureLobbies) {
      setFutureLobbies(null)
    } else {
      setVisible(false)
      setDisabledMenu(false)
    }
  }

  const handleLobbyCardPress = lobby => {
    Mixpanel.track('Lobby Card apretada', {
      Lobby: lobby
    })
    setSelectedLobby(lobby)
  }

  const handleBusCardPress = bus => {
    Mixpanel.track('Bus Card apretada', {
      Bus: bus
    })
    setSelectedBus(bus)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('schedule')) {
      setScheduleModal(true)
      params.delete('schedule')
      const newUrl = window.location.pathname + '?' + params.toString()
      window.history.replaceState({}, '', newUrl)
    }
  }, [])

  return (
    <>
      <Modal
        setVisible={setVisible}
        visible={visible}
        handleClose={handleClose}
        onLoad={() => setDisabledMenu(true)}
      >
        {pastLobbies ? (
          <div className='h-full overflow-auto pb-40 px-6'>
            <CardsList items={pastLobbies} handleLobbyCardPress={handleLobbyCardPress} />
            <ScheduleButton setScheduleModal={setScheduleModal} />
          </div>
        ) : futureLobbies ? (
          <div className='h-full overflow-auto pb-40 px-6'>
            <CardsList items={futureLobbies} handleLobbyCardPress={handleLobbyCardPress} />
            <ScheduleButton setScheduleModal={setScheduleModal} />
          </div>
        ) : (
          <LobbiesAndBusesList
            dataToFetchLobbies={dataToFetchLobbies}
            dataToFetchBuses={dataToFetchBuses}
            setPastLobbies={setPastLobbies}
            setFutureLobbies={setFutureLobbies}
            setScheduleModal={setScheduleModal}
            searchData={searchData}
            handleLobbyCardPress={handleLobbyCardPress}
            handleBusCardPress={handleBusCardPress}
          />
        )}
      </Modal>
      <ScheduleModal
        visible={scheduleModal}
        setVisible={setScheduleModal}
        initialValues={initialValues}
        searchData={searchData}
      />
      {selectedLobby && (
        <LobbyInfo
          lobby={selectedLobby}
          visible={selectedLobby}
          setVisible={setSelectedLobby}
          seats={searchData.seats}
          type='join'
          start={selectedLobby.Journey.start_name.locality}
          end={selectedLobby.Journey.end_name.locality}
        />
      )}
      {selectedBus && (
        <BusInfo
          bus={selectedBus}
          setSelectedBus={setSelectedBus}
          seats={searchData.seats}
          join={true}
        />
      )}
    </>
  )
}

export default SearchResults

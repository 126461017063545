import { uri } from '../../../../config/Api'
import { useAuthorizedFetch } from '../../../../hooks'
import { useEffect, useState } from 'react'
import { Mixpanel } from '../../../../Mixpanel'
import Alert from '../../../../commons/Alert'

export default ({ id, closeLobbyInfo, setLobbies, lobbies, setCancelRequest, reason }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/lobbies/${id}/cancel-request`, {
    method: 'DELETE',
    body: JSON.stringify({
      reason: reason
    })
  })
  const [errorAlert, setErrorAlert] = useState(false)

  useEffect(() => {
    if (response.ok) {
      closeLobbyInfo()
      Mixpanel.track('Solicitud de viaje cancelada con exito', {
        LobbyId: id,
        Motivo: reason
      })
      setLobbies(lobbies.filter(lobby => lobby._id != id))
    } else if (response.ok == false) {
      setErrorAlert(true)
      Mixpanel.track('Error al cancelar solicitud de viaje', {
        LobbyId: id,
        Motivo: reason
      })
    }
  }, [loading])

  return (
    <Alert
      visible={errorAlert}
      onClose={() => {
        setErrorAlert(false)
        setCancelRequest(false)
      }}
    >
      <p>
        Ha ocurrido un error, porfavor revise su conexión e intenta nuevamente y si el error
        persiste contáctate con nosotros
      </p>
    </Alert>
  )
}

import { Dialog, Slide } from '@mui/material'
import { forwardRef } from 'react'

export default ({ children, open, onClose, fullScreen, style }) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      style={style}
    >
      {children}
    </Dialog>
  )
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
